import axios from 'axios';
import appConfig from 'app-config';
import { logError } from '../../../../applications/account-management/src/components/AppInsightsComponent/AppInsightsComponent';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
/**
 * Custom Service class to call api for documents
 */
export default class CustomDocumentService {
/**
 * 1561098 - Method that displays the retrieved document from filenet via APIM 
 */
    static async displayDocument(auth, url) {
        const options = {
            headers: {
              'Content-Type': 'application/json',
              'Ocp-Apim-Subscription-Key': appConfig.env.APIM_KEY
            },
          }  
          
        await axios.get(url, options).then((response) => {
          const docData = response.data.RetrieveDocumentResponse.RetrieveDocument.dataSource;
          const decodedPDF = atob(docData);
          const len = decodedPDF.length;
          const arr = new Uint8Array(len);
          for(let i=0; i<len; i++){
            arr[i] = decodedPDF.charCodeAt(i);
          }
          const pdfBlob = new Blob([arr], {type: 'application/pdf'});
          const downloadLink = document.createElement('a');
          downloadLink.setAttribute('href', window.URL.createObjectURL(pdfBlob));
          downloadLink.style.display = 'none';
          downloadLink.target = '_blank';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          //1561098 - Removing the object once the pdf stream is downloaded and opened in the browser            
          downloadLink.remove();         
              
        }).catch((error) => {
           console.log(error);
           logError(error, SeverityLevel.Error, 'CustomDocumentService.displayDocument', 'Failed to display document for '+`${url}`)
        }); 
    }
}